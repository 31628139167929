<template>
  <div class="TextOverflow">
    <mescroll-vue class="mescroll" ref="mescroll">
      <div
        class="notice-item column"
        v-for="(item, index) in noticeList"
        :key="index"
      >
        <div class="head-box flex-r-sb">
          <span>系统通知</span>
          <span>{{ item.createTime }}</span>
        </div>
        <v-clamp class="content" autoresize :max-lines="item.isOpen ? 0 : 2">{{
          item.content
        }}</v-clamp>
        <!-- 展开/收起 -->
        <div class="expand-view row">
          <span @click="expandClick(item)">{{
            item.isOpen ? "收起" : "展开"
          }}</span>
          <img :src="item.isOpen ? item.isExpandImg : item.isOpenImg" />
        </div>
      </div>
    </mescroll-vue>
  </div>
</template>
<script>
export default {
  name: "TextOverflow",
  props: {
    noticeList: Array,
  },
  data() {
    return {};
  },
  methods: {
    expandClick(item) {
      // console.log(item);
      item.isOpen = !item.isOpen;
      if (item.status == 1) {
        this.$api.home.HomeNocticechange(item.id).then((res) => {
          this.$emit("showCityName")
        });
      }
    },
    // tabs Click
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>

<style lang="less" scoped>
.mescroll {
  height: calc(400px - 40px);
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 0 10px 10px 0;
}

.notice-item {
  margin-bottom: 24px;
  border-bottom: 1px solid #e9eaee;
  .head-box {
    padding-bottom: 8px;
    span {
      font-size: 16px;
      color: #323233;
      &:nth-child(2) {
        font-size: 12px;
        color: #969699;
      }
    }
  }

  .content {
    font-size: 14px;
    color: #969699;
  }

  .expand-view {
    align-self: flex-end;
    margin-bottom: 5px;
    // width: 100px;
    // height: 20px;
    padding: 0px 5px;
    cursor: pointer;
    img {
      width: 16px;
      height: 20px;
    }
  }
}
</style>
