<template>
  <div class="main">
    <div class="el-header-box">
      <img src="../assets/image/logo@2x.png" class="logo" />
      <span>{{userInfo.org_info.name}}</span>
      <div class="border"></div>
      <div class="name">拍照云</div>
    </div>

    <div class="content_wrap flex">
      <!-- 页面主体区域 -->
      <div class="aside_wrap">
        <template v-for="item in data">
          <div
            class="menus flex f_j_between f_a_center"
            :class="isCollapse ? 'active' : ''"
            @click="isCollapse = !isCollapse"
          >
            <div class="flex f_a_center">
              <img :src="item.icon" class="icon" />
              <span class="authName">{{ item.authName }}</span>
            </div>
            <img src="../assets/image/more@2x.png" class="arrow" :class="isCollapse ? 'active' : ''" />
          </div>
          <template v-if="item.children.length && isCollapse">
            <div
              class="submenu flex f_a_center"
              v-for="(subItem, index) in item.children"
              :key="index"
              :class="index == active ? 'active' : ''"
              @click="active = index ,changeRoute(subItem)"
            >
              <img :src="icon" class="icon" />
              <span class="authName">{{ subItem.authName }}</span>
            </div>
          </template>
        </template>
      </div>
      <!-- 内容部分 -->
      <div class="content f_g_1">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Breadrumb from "@/components/Breadrumb.vue"
import TextOverflow from "@/components/collapse/TextOverflow"
import merge from 'webpack-merge'

export default {
  name: "Main",
  data() {
    return {
      userInfo:{},
      menuList: [],
      // 记录点击path
      active: 0,
      icon: require("../assets/image/table@2x.png"),
      data: [
        {
          authName: "我的任务",
          icon: require("../assets/image/task@2x.png"),
          children: [
            // {
            //   authName: "广东开放大学学信网照片广东开放大学学信网照片",
            //   icon: require("../assets/image/table@2x.png")
            // },
            // {
            //   authName: "华农学信网照片采集",
            //   icon: require("../assets/image/table@2x.png")
            // }
          ],
          order: 3
        }
      ],
      isCollapse: true
    }
  },
  created() {
    let info = window.sessionStorage.getItem("user_info")
    this.userInfo = JSON.parse(info)
    this.getTaksList()
  },
  watch: {
    "$route.path": function() {
      this.activePath = window.sessionStorage.getItem("activePath")
    }
  },
  components: {
    Breadrumb,
    TextOverflow
  },
  methods: {
    changeRoute(item){
      let id =  this.$route.query.id
      let title =  this.$route.query.title
      if (id != item.id){
        this.$router.push({
          query:merge(this.$route.query,{'id':item.id,'title':item.authName})
        })
      }
    },
    getTaksList(){
      let that = this
      this.$api.task.menuList().then(res => {
        if (res.data){
          this.data[0].children = res.data
          this.changeRoute(this.data[0].children[0])
        }
      })
    },
    // 获取左侧菜单
    getMenuList() {
      let that = this
      that.$api.home.Enterprises().then(moke => {
        if (moke.code == 0) {
          if (moke.data.whetherAuthenticate !== 2) {
            let arr = this.$store.state.menuLeft
            for (let i = 0; i < arr.length; i++) {
              if (arr[i] == 373) {
                arr.splice(i, 1)
                this.$store.commit("updatamenuLeft", arr)
                // console.log(arr);
                // return;
              }
            }
          } else {
            let arr = this.$store.state.menuLeft
            if (arr.indexOf(373) < 0) {
              arr.push(373)
              this.$store.commit("updatamenuLeft", arr)
            }
          }
          //获取用户权限
          let arr = this.$store.state.menu
          //获取用户菜单栏权限id
          let ary = this.$store.state.menuLeft
          let menunav = []
          ary.forEach(ele => {
            for (let i = 0; i < arr.length; i++) {
              if (ele == arr[i].menuId) {
                menunav.push(arr[i].name)
                return
              }
            }
          })
          let array = []
          //判断是否有此菜单的权限
          this.data.forEach((ele, index) => {
            for (let i = 0; i < menunav.length; i++) {
              if (ele.authName.indexOf(menunav[i]) != -1) {
                array.push({
                  authName: ele.authName,
                  children: [],
                  icon: ele.icon,
                  id: ele.id,
                  order: ele.order,
                  path: ele.path
                })
                if (ele.children.length > 0) {
                  ele.children.forEach((item, ment) => {
                    for (let rein = 0; rein < menunav.length; rein++) {
                      if (item.authName.indexOf(menunav[rein]) != -1) {
                        array[array.length - 1].children.push({
                          authName: item.authName,
                          children: [],
                          icon: item.icon,
                          id: item.id,
                          order: item.order,
                          path: item.path
                        })
                        return
                      }
                    }
                  })
                }
                return
              }
              that.menuList = array
              // console.log(that.data);
            }
          })

          if (this.menuList.length == 0) {
            this.getMenuList()
          }
        }
      })
    },
    // 获取首页通知
    getMenuNotice() {
      let that = this
      that.$api.home.HomeNoctice(that.query).then(res => {
        if (res.code == 0) {
          that.historyList = []
          res.data.records.forEach(item => {
            item.isOpen = false
            item.isOpenImg = require("@/assets/image/dropdown.png")
            item.isExpandImg = require("@/assets/image/pushup.png")
            that.historyList.push(item)
          })
        }
      })
      that.$api.home.HomeNocticetop().then(res => {
        if (res.code == 0) {
          that.noticeList = []
          if (res.data.length > 0) {
            this.massation = true
          } else {
            this.massation = false
          }
          res.data.forEach(item => {
            item.isOpen = false
            item.isOpenImg = require("@/assets/image/dropdown.png")
            item.isExpandImg = require("@/assets/image/pushup.png")
            that.noticeList.push(item)
          })
        }
      })
    },
    handleSelect(index, indexPath) {
      console.log(indexPath, "indexPath")
      this.indexBreadcrumbs = indexPath
    },
    // 登出
    logout() {
      window.sessionStorage.clear()
      this.$router.push("/login")
    },
    // 保存链接link的激活状态
    saveNavState(activePath) {
      console.log(activePath, "activePath")
      this.activePath = activePath
      window.sessionStorage.setItem("activePath", activePath)
    },
    expandClick(item) {
      item.isOpen = !item.isOpen
    },
    // tabs Click
    handleClick(tab, event) {
      // console.log(tab, event);
    }
  }
}
</script>

<style lang="less">
.main {
  width: 100%;
  height: 100%;
  .el-header-box {
    z-index: 99;
    width: 100%;
    position: fixed;
    height: 72px !important;
    padding: 0 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e8e8e9;
    background-color: #ffffff;
    img {
      width: 40px;
      height: 40px;
      margin-right: 8px;
    }
    span {
      color: #262829;
      font-size: 24px;
      font-weight: bold;
    }

    .border {
      width: 0.01rem;
      height: 0.18rem;
      background-color: #d9d9d9;
      margin: 0 0.12rem;
    }
    .name {
      color: #9da1a3;
      font-size: 0.18rem;
    }
  }
  .content_wrap {
    height: 100%;
    padding-top: 72px;
    .f_g_1{
      padding-left: 285px;
    }
  }
  .aside_wrap {
    position: fixed;
    z-index: 99;
    width: 260px;
    height: 100%;
    background-color: #fafafa;
    padding: 12px 16px;
    .menus {
      width: 100%;
      height: 48px;
      padding: 12px;
      box-sizing: border-box;
      cursor: pointer;
      &.active {
        background: #eff0f0;
      }
    }
    .submenu {
      width: 100%;
      padding: 0 24px;
      box-sizing: border-box;
      height: 48px;
      cursor: pointer;
      & > span {
        width: 188px;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &.active .authName {
        color: #4d96ff !important;
      }
    }
    .icon {
      width: 24px;
      height: 24px;
    }

    .arrow {
      width: 16px;
      height: 16px;
      transform: rotate(0);
      transition: all 1s;
      &.active {
        transform: rotate(180deg);
      }
    }

    .authName {
      color: #262829;
      font-size: 16px;
    }
  }
}
</style>
