<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <!-- <el-breadcrumb-item :to="{ path: '/home' }">首页 </el-breadcrumb-item> -->
      <el-breadcrumb-item v-for="item in breadcrumbList" :key="item.id + ''">
        {{ item.authName }}
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  props: {
    menuList: {
      type: Array,
      defalut: [],
    },
    indexBreadcrumbs: {
      type: Array,
      defalut: [],
    },
  },
  data() {
    return {
      breadList: [], // 路由集合
    };
  },
  computed: {
    // 动态面包屑
    breadcrumbList() {
      let breadcrumbs = [];
      // 获取的菜单列表
      let menuList = this.menuList;
      // console.log(menuList, "menuList");

      // 获取面包屑路由菜单对象
      this.indexBreadcrumbs.map((item) => {
        // console.log(item, "item");
        for (let i = 0; i < menuList.length; i++) {
          // 如果是有二级菜单
          if (menuList[i].children !== undefined && menuList[i].children.length > 0 && item == menuList[i].id + "") {
            //item == "/" + menuList[i].path
            breadcrumbs.push(menuList[i]);
            continue;
          }
          // 如果没有二级菜单
          else if (menuList[i].children !== undefined && menuList[i].children.length == 0 && item == "/" + menuList[i].path) {
            breadcrumbs.push(menuList[i]);
            break;
          }
          // 如果是二级菜单
          else {
            for (let j = 0; j < menuList[i].children.length; j++) {
              if (item == "/" + menuList[i].children[j].path) {
                breadcrumbs.push(menuList[i].children[j]);
                break;
              }
            }
          }
        }
      });
      return breadcrumbs;
    },
  },
  //   watch: {
  //     $route() {
  //       this.getBreadcrumb();
  //     }
  //   },
  //   methods: {
  //     isHome(route) {
  //       return route.name === "home";
  //     },
  //     getBreadcrumb() {
  //       let matched = this.$route.matched;
  //       console.log(matched, "matched");
  //       //如果不是首页就拼接路由
  //       if (!this.isHome(matched[0])) {
  //         matched = [{ path: "/home", meta: { title: "首页" } }].concat(matched);
  //       }
  //       this.breadList = matched;
  //     }
  //   },
  //   created() {
  //     this.getBreadcrumb();
  //   }
};
</script>

<style lang="less" scoped></style>
